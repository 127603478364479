import React from 'react'
import Helmet from 'react-helmet'
import './SEMrushAuditForm.scss'

const SEMrushAuditForm = () => (
  <div id="ssa-widget" className="mv4" style={{fontFamily: 'inherit'}}>
    <Helmet>
      <script type="text/javascript">
        {`
          var p = "https://www.semrush.com/leadgen/static/js/widget.min.js";
          var d = document;
          var s = d.createElement("script");
          s.src = p + "?v=" + Math.random();
          (d.head || d.body).appendChild(s);
          s.onload = function() {
            new SEMRUSH.SiteauditWidget({
              widgetApiKey: "5c2e6349f85646126531d553"
            });
          }
        `}
      </script>
    </Helmet>
  </div>
)

export default SEMrushAuditForm
